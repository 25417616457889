import React, { useState, useEffect } from "react";
import openAiToken from "../constants/apiKeys";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BounceLoader } from "react-spinners";
import Modal from "react-modal";

Modal.setAppElement("#root");

const RoadTripPreferences = () => {
  const [startDestination, setStartDestination] = useState("");
  const [destination, setDestination] = useState("");
  const [travelDays, setTravelDays] = useState(1);
  const [travelMonth, setTravelMonth] = useState("January");
  const [schedulePackedness, setSchedulePackedness] = useState("relaxed");
  const [budget, setBudget] = useState("economy");
  const [prompt, setPrompt] = useState("");
  const [itinerary, setItinerary] = useState("");
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [interests, setInterests] = useState({
    food: false,
    sports: false,
    hikes: false,
    outdoors: false,
    history: false,
    culture: false,
    relaxation: false,
    nightlife: false,
    shopping: false,
    scenicRoutes: false,
    landmarks: false,
  });

  const buttonClass =
    startDestination && destination ? "button-enabled" : "button-disabled";

  const handleInterestChange = (interest) => {
    setInterests({ ...interests, [interest]: !interests[interest] });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setIsModalOpen(true);
    const itineraryPrompt = generateItineraryPrompt({
      startDestination,
      destination,
      travelDays,
      travelMonth,
      schedulePackedness,
      budget,
      interests,
    });
    setPrompt(itineraryPrompt);
    fetchOpenAIResponse(itineraryPrompt);
  };

  const generateItineraryPrompt = ({
    startDestination,
    destination,
    travelDays,
    travelMonth,
    schedulePackedness,
    budget,
    interests,
  }) => {
    const interestList = Object.entries(interests)
      .filter(([key, value]) => value)
      .map(([key]) => key.charAt(0).toUpperCase() + key.slice(1))
      .join(", ");

    const schedulePackednessDescription = {
      relaxed: "a relaxed schedule with more free time",
      moderate: "a moderate schedule balancing attractions and leisure",
      packed: "a packed schedule with lots of activities",
    };

    const budgetDescription = {
      economy: "an economy budget with cost-effective options",
      comfort: "a comfortable budget with some indulgences",
      luxury: "a luxurious experience with high-end options",
    };

    // Use the updated parameter names here
    return `Plan a ${travelDays}-day road trip from ${startDestination} to ${destination} in ${travelMonth}. I would like ${schedulePackednessDescription[schedulePackedness]}. My budget is ${budgetDescription[budget]}. I am interested in ${interestList}. Please include activities, dining options, and accommodation recommendations that fit within this criteria. Include emojis to make the itinerary more appealing to users. Include addresses and additional information on places mentioned.`;
  };

  const fetchOpenAIResponse = async (prompt) => {
    const apiBody = {
      model: "gpt-3.5-turbo",
      messages: [{ role: "user", content: prompt }],
      max_tokens: 4000,
    };

    try {
      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${openAiToken}`,
          },
          body: JSON.stringify(apiBody),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const responseData = await response.json();
      setItinerary(responseData.choices[0].message.content);
    } catch (error) {
      console.error("There was an error!", error);
    } finally {
      setLoading(false);
      setIsModalOpen(false);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(itinerary).then(
      () => {
        toast.success("Itinerary copied to clipboard!");
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return !prompt ? (
    <div className="trip-preferences">
      <h2>Customize your trip</h2>
      <form onSubmit={handleSubmit}>
        <div className="section">
          <label htmlFor="destination">Start of your road trip:</label>
          <input
            type="text"
            id="destination"
            value={startDestination}
            onChange={(e) => setStartDestination(e.target.value)}
          />
        </div>

        <div className="section">
          <label htmlFor="endDestination">End of your road trip:</label>
          <input
            type="text"
            id="endDestination"
            value={destination}
            onChange={(e) => setDestination(e.target.value)}
          />
        </div>

        <div className="section">
          <label htmlFor="travelDays">How many days are you staying?</label>
          <select
            id="travelDays"
            value={travelDays}
            onChange={(e) => setTravelDays(Number(e.target.value))}
          >
            {[...Array(15).keys()].map((day) => (
              <option key={day} value={day + 1}>
                {day + 1}
              </option>
            ))}
          </select>
        </div>

        <div className="section">
          <label htmlFor="month">What month are you travelling?</label>
          <select
            id="month"
            value={travelMonth}
            onChange={(e) => setTravelMonth(e.target.value)}
          >
            {[
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December",
            ].map((month) => (
              <option key={month} value={month}>
                {month}
              </option>
            ))}
          </select>
        </div>

        <div className="section">
          <label htmlFor="schedulePackedness">
            How packed do you like your schedule?
          </label>
          <select
            id="schedulePackedness"
            value={schedulePackedness}
            onChange={(e) => setSchedulePackedness(e.target.value)}
          >
            <option value="relaxed">Relaxed</option>
            <option value="moderate">Moderate</option>
            <option value="packed">Packed</option>
          </select>
        </div>

        <div className="section">
          <label htmlFor="budget">What is your budget?</label>
          <select
            id="budget"
            value={budget}
            onChange={(e) => setBudget(e.target.value)}
          >
            <option value="economy">Economy</option>
            <option value="comfort">Comfort</option>
            <option value="luxury">Luxury</option>
          </select>
        </div>

        <fieldset>
          <legend>Interests:</legend>
          {Object.keys(interests).map((interest) => (
            <div key={interest}>
              <input
                type="checkbox"
                id={interest}
                checked={interests[interest]}
                onChange={() => handleInterestChange(interest)}
              />
              <label htmlFor={interest}>
                {interest.charAt(0).toUpperCase() + interest.slice(1)}
              </label>
            </div>
          ))}
        </fieldset>
        <div style={{ textAlign: "center" }}>
          <button
            type="submit"
            disabled={!startDestination || !destination}
            className={buttonClass}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  ) : (
    <div className="thank-you-container">
      <main className="main-content">
        <h1>Thank You for Choosing LowPriceTravels!</h1>
        <p>
          Now generating your travel plan for your road trip from{" "}
          {startDestination} to {destination}. Our AI uses your interests to
          create specialized itineraries for you. Do not refresh the page during
          processing. While you wait, why not explore some options for your
          trip?
        </p>
        <div className="links-container">
          <h3>Discover More for Your Journey:</h3>
          <a
            href="https://www.booking.com/index.html?aid=8084301"
            target="_blank"
            rel="noopener noreferrer"
            className="booking-link"
          >
            Hotels & Rentals at Booking.com
          </a>
          <a
            href="https://www.getyourguide.com?partner_id=M6ML54R&cmp=share_to_earn"
            target="_blank"
            rel="noopener noreferrer"
            className="guide-link"
          >
            Attractions & Guides at GetYourGuide.com
          </a>
        </div>
      </main>
      <div className={loading ? "loading-container" : "itinerary-container"}>
        {loading ? (
          <Modal
            isOpen={isModalOpen}
            onRequestClose={() => setIsModalOpen(false)}
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              content: {
                position: "absolute",
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                width: screenWidth > 600 ? "500px" : "90vw",
                maxHeight: "80%",
                transform: "translate(-50%, -50%)",
                padding: "20px",
                borderRadius: "8px",
                border: "none",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                overflowY: "auto",
              },
            }}
          >
            <div
              className="modal-content"
              style={{
                fontSize: "24px",
                lineHeight: "1.5",
                color: "#4C959F",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div>
                <BounceLoader color="#4C959F" loading={loading} />
              </div>
              <p>This should only take a few seconds...</p>
            </div>
          </Modal>
        ) : (
          <div className="itinerary-content">
            <div>
              {itinerary.split("\n").map((line, index) => (
                <p key={index}>{line}</p>
              ))}
              <button
                className="copy-btn"
                style={{ marginRight: "10px" }}
                onClick={copyToClipboard}
              >
                Copy Itinerary
              </button>
              <button
                className="copy-btn"
                onClick={() =>
                  window.open(
                    "https://www.booking.com/index.html?aid=8084301",
                    "_blank"
                  )
                }
                style={{ backgroundColor: "#017BFE" }}
              >
                Book Trip
              </button>
            </div>
            <iframe
              title="Newsletter"
              src="https://embeds.beehiiv.com/14691cb2-f4ec-4976-add9-fddfadfc4092"
              data-test-id="beehiiv-embed"
              width="100%"
              height="320"
              frameborder="0"
              style={{
                borderRadius: "4px",
                border: "2px solid #e5e7eb",
                margin: "0",
                marginBottom: "20px",
                marginTop: "20px",
                backgroundColor: "transparent",
              }}
            ></iframe>
          </div>
        )}
      </div>
      <ToastContainer position="bottom-left" autoClose={2000} />
    </div>
  );
};

export default RoadTripPreferences;
