import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <nav style={screenWidth < 900 ? styles.navbar : styles.normalNavbar}>
      <div style={styles.brandContainer}>
        <span style={styles.brand}>LOWPRICETRAVELS</span>
        {screenWidth < 900 && (
          <button style={styles.toggleButton} onClick={toggleDrawer}>
            ☰
          </button>
        )}
      </div>
      {screenWidth < 900 && isDrawerOpen && (
        <div style={styles.drawer}>
          <Link style={styles.drawerItem} to="/" onClick={toggleDrawer}>
            Flight Search
          </Link>
          <Link
            style={styles.drawerItem}
            to="/city-trip"
            onClick={toggleDrawer}
          >
            City Trip
          </Link>
          <Link
            style={styles.drawerItem}
            to="/road-trip"
            onClick={toggleDrawer}
          >
            Road Trip
          </Link>
          <Link style={styles.drawerItem} to="/blog" onClick={toggleDrawer}>
            Blog
          </Link>
        </div>
      )}
      {screenWidth >= 900 && (
        <div style={styles.normalDrawer}>
          <Link style={styles.drawerItem} to="/">
            Flight Search
          </Link>
          <Link style={styles.drawerItem} to="/city-trip">
            City Trip
          </Link>
          <Link style={styles.drawerItem} to="/road-trip">
            Road Trip
          </Link>
          <Link style={styles.drawerItem} to="/blog">
            Blog
          </Link>
        </div>
      )}
    </nav>
  );
};

const styles = {
  navbar: {
    backgroundColor: "#FE737B",
    color: "#fff",
    padding: "20px",
    position: "relative",
  },
  normalNavbar: {
    backgroundColor: "#FE737B",
    color: "#fff",
    padding: "10px 10%",
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
  },
  brandContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  brand: {
    fontWeight: "bold",
    fontSize: "24px",
  },
  toggleButton: {
    fontSize: "24px",
    cursor: "pointer",
    backgroundColor: "transparent",
    border: "none",
    color: "white",
    outline: "none",
  },
  drawer: {
    position: "absolute",
    top: "100%",
    left: 0,
    backgroundColor: "#FE737B",
    width: "100%",
    padding: "10px",
    boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
    zIndex: "999",
  },
  normalDrawer: {
    display: "flex",
    justifyContent: "end",
    backgroundColor: "#FE737B",
    width: "100%",
    paddingLeft: "10px",
  },
  drawerItem: {
    color: "#fff",
    textDecoration: "none",
    display: "block",
    padding: "10px",
    fontSize: "18px",
    transition: "background-color 0.3s",
    "&:hover": {
      backgroundColor: "#555",
      borderRadius: "5px",
    },
  },
};

export default Navbar;
