import React, { useState } from "react";
import FlightSearch from "./flightSearchComponent";
import SelectTrip from "./selectTrip";
import Reviews from "./reviews";
import TripsList from "./tripsList";

const HomePage = () => {
  const [flightSearchDetails, setFlightSearchDetails] = useState([]);
  const [departureLocation, setDepartureLocation] = useState("");
  const [arrivalLocation, setArrivalLocation] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [noResultsError, setNoResultsError] = useState(false);
  const [admin, setAdmin] = useState(false);

  return (
    <div>
      <header style={styles.header}>
        <FlightSearch
          searchDetails={flightSearchDetails}
          setSearchDetails={setFlightSearchDetails}
          departureLocation={departureLocation}
          setDepartureLocation={setDepartureLocation}
          arrivalLocation={arrivalLocation}
          setArrivalLocation={setArrivalLocation}
          selectedMonth={selectedMonth}
          setSelectedMonth={setSelectedMonth}
          setAdmin={setAdmin}
          setNoResultsError={setNoResultsError}
        />
      </header>
      <main style={styles.mainContent}>
        {flightSearchDetails.length ? (
          <div className="trip-card-container">
            <h2>
              Our AI found the cheapest times to fly from{" "}
              <span style={{ fontSize: "28px", color: "#FE737B" }}>
                {departureLocation}
              </span>{" "}
              to{" "}
              <span style={{ fontSize: "28px", color: "#FE737B" }}>
                {arrivalLocation}
              </span>{" "}
              in{" "}
              <span style={{ fontSize: "28px", color: "#FE737B" }}>
                {selectedMonth}
              </span>{" "}
              are:
            </h2>

            <iframe
              title="Newsletter"
              src="https://embeds.beehiiv.com/14691cb2-f4ec-4976-add9-fddfadfc4092"
              data-test-id="beehiiv-embed"
              width="100%"
              height="320"
              frameborder="0"
              style={{
                borderRadius: "4px",
                border: "2px solid #e5e7eb",
                margin: "0",
                marginBottom: "20px",
                backgroundColor: "transparent",
              }}
            ></iframe>
            <p style={{ color: "#FE737B" }}>
              ** Flight prices are sometimes inaccurate due to initial loads on
              certain sites **
            </p>
            <TripsList
              flightSearchDetails={flightSearchDetails}
              admin={admin}
            />
          </div>
        ) : null}
        {noResultsError ? (
          <>
            <p
              style={{
                color: "#FE737B",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              ** Our apologies, we couldn't find any flights with the locations
              and month you specified. Try again with new inputs. Thank you! **
            </p>
            <iframe
              title="Newsletter"
              src="https://embeds.beehiiv.com/14691cb2-f4ec-4976-add9-fddfadfc4092"
              data-test-id="beehiiv-embed"
              width="100%"
              height="300"
              frameborder="0"
              style={{
                borderRadius: "4px",
                margin: "0",
                marginBottom: "20px",
                marginTop: "20px",
                backgroundColor: "transparent",
              }}
            ></iframe>
          </>
        ) : null}
        <section>
          <SelectTrip />
        </section>
        <section>
          <Reviews />
        </section>
      </main>
    </div>
  );
};

const styles = {
  header: {
    textAlign: "center",
    backgroundColor: "#f8f8f8",
  },
  mainContent: {
    padding: "20px",
  },
};

export default HomePage;
