import React from "react";
import Modal from "react-modal";

// Ensure to set the app element to avoid accessibility issues
Modal.setAppElement("#root");

const NewsletterModal = (props) => {
  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.closeModal}
      contentLabel="Newsletter Sign Up"
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          border: "1px solid #ccc",
          background: "#fff",
          overflow: "auto",
          WebkitOverflowScrolling: "touch",
          borderRadius: "4px",
          outline: "none",
          padding: "20px",
          width: "80%",
        },
      }}
    >
      <iframe
        title="Newsletter"
        src="https://embeds.beehiiv.com/14691cb2-f4ec-4976-add9-fddfadfc4092"
        data-test-id="beehiiv-embed"
        width="100%"
        height="320"
        frameborder="0"
        style={{
          borderRadius: "4px",
          border: "2px solid #e5e7eb",
          margin: "0",
          backgroundColor: "transparent",
        }}
      ></iframe>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          onClick={props.closeModal}
          style={{
            border: "none",
            cursor: "pointer",
            fontSize: "18px",
            display: "inline-block",
            padding: "10px 15px",
            backgroundColor: "#FE737B",
            color: "#ffffff",
            textDecoration: "none",
            borderRadius: "4px",
            fontWeight: "bold",
          }}
        >
          Close
        </button>
      </div>
    </Modal>
  );
};

export default NewsletterModal;
