import React, { useEffect, useState } from "react";
import Navbar from "./navigation/navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./components/homePage";
import Footer from "./navigation/footer";
import CityTripPreferences from "./components/cityTripPreferences";
import RoadTripPreferences from "./components/roadTripPreferences";
import BlogListing from "./components/blogPosts";
import NewsletterModal from "./components/newsletterModal";

const App = () => {
  const [showModal, setShowModal] = useState(false);

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowModal(true);
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <div className="app-container">
        <Navbar />
        <div className="body-container">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/city-trip" element={<CityTripPreferences />} />
            <Route path="/road-trip" element={<RoadTripPreferences />} />
            <Route path="/blog" element={<BlogListing />} />
          </Routes>
        </div>
        <Footer />
        {showModal && (
          <NewsletterModal isOpen={showModal} closeModal={closeModal} />
        )}
      </div>
    </Router>
  );
};

export default App;
