import React from "react";
import { useNavigate } from "react-router-dom";

const SelectTrip = () => {
  let navigate = useNavigate();

  return (
    <div className="itinerary-section">
      <h2>Our AI can also help you create an itinerary for your trip</h2>
      <div className="itinerary-counter">12228 itineraries created so far</div>
      <div className="options-container">
        <div className="option-card">
          <div className="icon">🏙️</div>
          <h3>City trip</h3>
          <p>Start building your personalized city trip itinerary</p>
          <button onClick={() => navigate("/city-trip")}>Select</button>
        </div>
        <div className="option-card new">
          <div className="icon">🚗</div>
          <h3>Road trip</h3>
          <p>Start building your personalized road trip itinerary</p>
          <button onClick={() => navigate("/road-trip")}>Select</button>
        </div>
      </div>
    </div>
  );
};

export default SelectTrip;
