import React from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TripCard = (props) => {
  const getDatesList = () => {
    const dates = props.trip.dates;
    if (!dates || dates.length === 0) {
      return "";
    }
    return dates.join("\u00A0\u00A0|\u00A0\u00A0");
  };

  const copyText = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Content copied to clipboard", {});
  };

  const handleCopy = () => {
    const tripInfo = `${props.trip.month} \n${
      props.trip.price
    } \n${getDatesList()}`;
    copyText(tripInfo);
  };

  const handleCopyForNewsletter = () => {
    const newsletterInfo = `${props.trip.price} - ${getDatesList()}`;
    copyText(newsletterInfo);
  };

  return (
    <div className="trip-card">
      <div className="trip-details">
        <h2>{props.trip.month}</h2>
        <p>{getDatesList()}</p>
        <h3>{props.trip.price}</h3>
        <a
          href="https://aviasales.tp.st/zGYVXqhz"
          target="_blank"
          rel="noopener noreferrer"
        >
          BOOK NOW
        </a>
        <button className="copy" onClick={() => handleCopy()}>
          Copy
        </button>
        {props.admin && (
          <button
            className="copy-newsletter"
            onClick={() => handleCopyForNewsletter()}
          >
            Copy for Newsletter
          </button>
        )}
      </div>
      <ToastContainer position="bottom-left" autoClose={2000} />
    </div>
  );
};

const TripsList = (props) => {
  return props.flightSearchDetails.map((trip, index) => (
    <TripCard key={index} trip={trip} admin={props.admin} />
  ));
};

export default TripsList;
