import React from "react";
import producthunt from "../images/producthunt.png";
import TheInsaneApp from "../images/TheInsaneApp.png";
import person1 from "../images/person1.png";
import person2 from "../images/person2.png";
import person3 from "../images/person3.png";

const Testimonial = ({ image, body }) => {
  return (
    <div className="testimonial">
      <img src={image} alt="Testimonial" className="testimonial-image" />
      <h3 className="testimonial-body">{body}</h3>
    </div>
  );
};

const Reviews = () => {
  const openInsaneAppReview = () => {
    window.open(
      "https://www.theinsaneapp.com/tools/travelmoji-review/",
      "_blank"
    );
  };

  return (
    <div className="reviews-container">
      <h3 className="featured-on">AS FEATURED ON</h3>
      <div className="featured-images">
        <img src={producthunt} alt="Product Hunt" className="featured-image" />
        <img
          src={TheInsaneApp}
          alt="The Insane App"
          className="featured-image"
          onClick={openInsaneAppReview}
        />
      </div>
      <div className="testimonials">
        <Testimonial
          image={person1}
          body="LowPriceTravels made planning my vacation a breeze! With just a few clicks, I was able to customize my itinerary and explore the best destinations within my budget. I highly recommend LowPriceTravels to anyone who wants to take the hassle out of trip planning."
        />
        <Testimonial
          image={person2}
          body="I've used many travel planning tools in the past, but LowPriceTravels is by far the best. The AI-powered suggestions were spot on, and the platform was easy to use. I was able to plan a trip that I never would have been able to without LowPriceTravels. Thanks for making my travel dreams a reality!"
        />
        <Testimonial
          image={person3}
          body="As a frequent traveler, I've tried many travel planning tools, but LowPriceTravels stands out from the rest. The platform was intuitive and user-friendly, and the AI-powered suggestions were incredibly helpful in creating an itinerary that met all of my needs. I highly recommend LowPriceTravels to anyone looking to plan the perfect trip."
        />
      </div>
    </div>
  );
};

export default Reviews;
