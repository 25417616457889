import React from "react";

const Footer = () => {
  return (
    <footer style={styles.footer}>
      © 2024 LOWPRICETRAVELS. All rights reserved.
    </footer>
  );
};

const styles = {
  footer: {
    textAlign: "center",
    padding: "20px",
    backgroundColor: "#FE737B",
    color: "white",
  },
};

export default Footer;
