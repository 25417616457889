import React from "react";

const BlogPosts = [
  {
    title: "5 Top Tips for Finding Cheap Flights from Salt Lake City",
    link: "https://lowpricetravels.beehiiv.com/p/5-top-tips-finding-cheap-flights-salt-lake-city",
    description:
      "Unlock the secrets to snagging the cheapest flights from Salt Lake City with our top 5 tips for budget travel. Learn how to search smarter, not harder.",
  },
  {
    title: "The Best Times to Book Cheap Flights from SLC",
    link: "https://lowpricetravels.beehiiv.com/p/best-times-book-cheap-flights-slc",
    description:
      "Timing is everything when it comes to booking cheap flights. Discover the best times to book your flights from Salt Lake City to save big on airfare.",
  },
  {
    title: "Salt Lake City Flight Deals: How to Use Layovers to Your Advantage",
    link: "https://lowpricetravels.beehiiv.com/p/best-times-book-cheap-flights-slc",
    description:
      "Turn layovers into opportunities with our guide on how to use extended layovers to explore new cities or save money on flights from Salt Lake City.",
  },
  {
    title: "Budget Airlines vs. Major Carriers: Flying out of Salt Lake City",
    link: "https://lowpricetravels.beehiiv.com/p/budget-airlines-vs-major-carriers-flying-salt-lake-city",
    description:
      "Compare budget airlines with major carriers for flights out of Salt Lake City. Which offers the best value for your travel needs? Find out here.",
  },
  {
    title: "Maximizing Rewards and Points for Cheap Flights from SLC",
    link: "https://lowpricetravels.beehiiv.com/p/maximizing-rewards-points-cheap-flights-slc",
    description:
      "Learn how to maximize your airline rewards and credit card points to get the cheapest flights from Salt Lake City. A must-read for savvy travelers.",
  },
  {
    title:
      "Flight Deals from SLC to London, New York City, Dominican Republic, Denver",
    link: "https://lowpricetravels.beehiiv.com/p/flight-deals-slc-london-new-york-city-dominican-republic-denver",
    description:
      "Discover incredible flight deals from Salt Lake City to London, New York City, the Dominican Republic, and Denver. Save big on your next adventure.",
  },
  {
    title:
      "Flight Deals from SLC to Dallas, Switzerland, Nashville, Washington DC",
    link: "https://lowpricetravels.beehiiv.com/p/flight-deals-slc-dallas-switzerland-nashville-washington-dc",
    description:
      "Explore amazing deals on flights from Salt Lake City to Dallas, Switzerland, Nashville, and Washington DC. Book your trip today and enjoy great savings.",
  },
  {
    title: "Deals: Cancún, Miami, Paris, Phoenix",
    link: "https://lowpricetravels.beehiiv.com/p/deals-cancn-miami-paris-phoenix",
    description:
      "Take advantage of exclusive deals to Cancún, Miami, Paris, and Phoenix. Perfect for travelers looking for their next city break or beach vacation.",
  },
  {
    title: "Deals: Honolulu, Orlando, Barcelona",
    link: "https://lowpricetravels.beehiiv.com/p/deals-honolulu-orlando-barcelona",
    description:
      "Get the best deals on trips to Honolulu, Orlando, and Barcelona. Ideal for those who love both the excitement of the city and the relaxation of the beach.",
  },
  {
    title: "Deals: New York City, Paris, Portland",
    link: "https://lowpricetravels.beehiiv.com/p/deals-new-york-city-paris-portland",
    description:
      "Discover exclusive deals to New York City, Paris, and Portland. Don't miss out on the chance to explore these vibrant cities at unbeatable prices.",
  },
  {
    title: "Deals: Phoenix, Honolulu, Boise, Tokyo",
    link: "https://lowpricetravels.beehiiv.com/p/deals-phoenix-honolulu-boise-tokyo",
    description:
      "Explore our special deals to Phoenix, Honolulu, Boise, and Tokyo. A perfect mix of urban exploration and natural beauty awaits.",
  },
];

const BlogListing = () => {
  return (
    <main style={{ maxWidth: "800px", margin: "40px auto", padding: "0 20px" }}>
      <section>
        <h2
          style={{
            color: "#333",
            fontSize: "1.8rem",
            borderBottom: "2px solid #4C959F",
            paddingBottom: "10px",
          }}
        >
          Latest Travel Deals and Offers
        </h2>
        <iframe
          title="Newsletter"
          src="https://embeds.beehiiv.com/14691cb2-f4ec-4976-add9-fddfadfc4092"
          data-test-id="beehiiv-embed"
          width="100%"
          height="300"
          frameborder="0"
          style={{
            borderRadius: "4px",
            margin: "0",
            marginBottom: "20px",
            marginTop: "20px",
            backgroundColor: "transparent",
          }}
        ></iframe>
        <p style={{ fontSize: "16px", lineHeight: "1.6", marginTop: "20px" }}>
          Explore our curated list of blog posts that hold the latest travel
          deals and offers to find your next adventure at a fraction of the
          price. Whether you're looking for a city escape, a beach vacation, or
          an international adventure, our content offers tips and tricks to help
          you plan vacations for less.
        </p>

        <ul style={{ listStyleType: "none", padding: 0 }}>
          {BlogPosts.map((post, index) => (
            <li key={index} style={{ marginBottom: "30px" }}>
              <a
                href={post.link}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "#4C959F",
                }}
              >
                {post.title}
              </a>
              <p style={{ fontSize: "16px", marginTop: "5px" }}>
                {post.description}
              </p>
            </li>
          ))}
        </ul>
      </section>
    </main>
  );
};

export default BlogListing;
